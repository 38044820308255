export default (query, data) => {
  function result(field) {
    for (const key in field) {
      if (field.hasOwnProperty(key)) {
        const val = field[key];

        if (!val) {
          continue;
        }

        if (typeof val === 'object') {
          if (result(val)) {
            return true;
          }

          continue;
        }

        if (val.toString().toLowerCase().includes(query.toLowerCase())) {
          return true;
        }
      }
    }

    return false;
  }

  function search(searchData) {
    return searchData.filter((item) => result(item));
  }

  return query ? search(data) : null;
};
