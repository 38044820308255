<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>{{ $t('form.filter') }}</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol class="col-12 col-sm-4 col-xl-3">
              <CInput
                :value="search"
                size="sm"
                :label="$t('form.search')"
                :placeholder="$t('form.enter_query')"
                @input="$emit('updateSearchQuery', $event)"
              >
                <template #append>
                  <CButton size="sm" color="info" @click="$emit('resetSearch')">
                    {{ $t('form.reset') }}
                  </CButton>
                </template>
              </CInput>
            </CCol>

            <CCol class="col-12 col-sm-4 col-xl-3">
              <CSelect
                :value="limit"
                size="sm"
                :label="$t('form.limits')"
                :options="limits"
                custom
                @input="$emit('updateLimit', $event.target.value)"
              />
            </CCol>

            <CCol v-if="isIncludeSiteIdFilter" class="col-12 col-sm-4 col-xl-3">
              <div class="form-group">
                <label class="col-form-label-sm" for="site-id">{{ $t('form.site') }}</label>
                <multiselect
                  id="site-id"
                  :value="site"
                  :placeholder="$t('form.site')"
                  :options="siteList"
                  :show-labels="false"
                  track-by="value"
                  label="label"
                  @input="$emit('filterBySiteId', $event)"
                >
                  <template #noResult>{{ $t('form.not_found_elements') }}</template>
                </multiselect>
              </div>
            </CCol>

            <CCol v-if="isIncludeCampaignIdFilter" class="col-12 col-sm-4 col-xl-3">
              <div class="form-group">
                <label class="col-form-label-sm" for="campaign-id">{{ $t('form.campaign') }}</label>
                <multiselect
                  id="campaign-id"
                  :value="campaign"
                  :placeholder="$t('form.campaign')"
                  :options="mappedCampaignList"
                  :show-labels="false"
                  track-by="value"
                  label="label"
                  @input="$emit('filterByCampaignId', $event)"
                >
                  <template #noResult>{{ $t('form.not_found_elements') }}</template>
                </multiselect>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { GET_SITES_LIST } from '@/store/action-types/sites';
import { GET_CAMPAIGN_LIST } from '@/store/action-types/campaigns';
import { limits, filtersDefaultLimit } from '@/helpers/const';

export default {
  name: 'MbnTableTools',
  props: {
    limit: {
      type: [String, Number],
      required: true,
    },
    search: {
      type: String,
      required: true,
      default: '',
    },
    isIncludeSiteIdFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    siteId: {
      type: [String, Number],
      required: false,
      default: '',
    },
    isIncludeCampaignIdFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    campaignId: {
      type: [String, Number],
      required: false,
      default: '',
    },
  },
  data() {
    return {
      limits,
    };
  },
  computed: {
    ...mapGetters('sites', ['siteList']),
    ...mapGetters('campaigns', ['mappedCampaignList']),
    site() {
      return this.siteList.find((site) => site.value == this.siteId);
    },
    campaign() {
      return this.mappedCampaignList.find((campaign) => campaign.value == this.campaignId);
    },
  },
  mounted() {
    if (this.isIncludeSiteIdFilter) {
      this.GET_SITES_LIST({ limit: filtersDefaultLimit });
    }

    if (this.isIncludeCampaignIdFilter) {
      this.GET_CAMPAIGN_LIST({ limit: filtersDefaultLimit });
    }
  },
  methods: {
    ...mapActions('sites', [GET_SITES_LIST]),
    ...mapActions('campaigns', [GET_CAMPAIGN_LIST]),
  },
};
</script>
