<template>
  <p>{{ error }}</p>
</template>

<script>
export default {
  name: 'MbnErrorMessage',
  props: {
    message: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    error() {
      return this.message ? this.message : this.$t('errors.something_error');
    },
  },
};
</script>
