var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MbnTableTools',{attrs:{"search":_vm.search,"limit":_vm.limit},on:{"updateSearchQuery":function($event){_vm.search = $event},"updateLimit":_vm.updateLimit,"resetSearch":_vm.resetSearch}}),_c('CRow',[_c('CCol',[_c('CCard',[_c('CCardHeader',{staticClass:"d-flex align-items-center justify-content-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('sidebar.campaigns')))]),_c('CButton',{attrs:{"size":"sm","color":"info"},on:{"click":_vm.createNewRecord}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" "+_vm._s(_vm.$t('ui.add_new_campaign')))],1)],1),_c('CCardBody',[(_vm.error)?_c('MbnErrorMessage'):_c('CDataTable',{attrs:{"fields":_vm.fields,"items":_vm.tableItems,"striped":"","add-table-classes":"section-table","no-items-view":{ noItems: _vm.$t('ui.no_items_available') },"sorter":"","clickable-rows":"","loading":_vm.isLoading,"hover":""},on:{"row-clicked":_vm.edit},scopedSlots:_vm._u([{key:"browser",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.browser)?_c('CListGroup',_vm._l((item.browser),function(browser,index){return _c('CListGroupItem',{key:index,staticClass:"table-list-group"},[_vm._v(" "+_vm._s(browser.name)+" ")])}),1):_c('span',[_vm._v(" "+_vm._s(_vm.$t('ui.all'))+" ")])],1)]}},{key:"country",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.country)?_c('CListGroup',_vm._l((item.country),function(country,index){return _c('CListGroupItem',{key:index,staticClass:"table-list-group"},[_vm._v(" "+_vm._s(country.name)+" ")])}),1):_c('span',[_vm._v(" "+_vm._s(_vm.$t('ui.all'))+" ")])],1)]}},{key:"language",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.language)?_c('CListGroup',_vm._l((item.language),function(language,index){return _c('CListGroupItem',{key:index,staticClass:"table-list-group"},[_vm._v(" "+_vm._s(language.name)+" ")])}),1):_c('span',[_vm._v(" "+_vm._s(_vm.$t('ui.all'))+" ")])],1)]}},{key:"platform",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.platform)?_c('CListGroup',_vm._l((item.platform),function(platform,index){return _c('CListGroupItem',{key:index,staticClass:"table-list-group"},[_vm._v(" "+_vm._s(platform.name)+" ")])}),1):_c('span',[_vm._v(" "+_vm._s(_vm.$t('ui.all'))+" ")])],1)]}},{key:"siteWhiteList",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CListGroup',_vm._l((item.siteWhiteList),function(site,index){return _c('CListGroupItem',{key:index,staticClass:"table-list-group"},[_vm._v(" "+_vm._s(site.name)+" ")])}),1)],1)]}},{key:"active",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.active)}},[_vm._v(" "+_vm._s(item.active ? _vm.$t('ui.active') : _vm.$t('ui.inactive'))+" ")])],1)]}},{key:"show_details",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex"},[[(item.active)?_c('CButton',{staticClass:"mr-3",attrs:{"title":_vm.$t('ui.stop'),"size":"sm","color":"info"},on:{"click":function($event){return _vm.deactivate(item)}}},[_c('CIcon',{attrs:{"name":"cil-media-pause"}})],1):_c('CButton',{staticClass:"mr-3",attrs:{"title":_vm.$t('ui.start'),"size":"sm","color":"info"},on:{"click":function($event){return _vm.activate(item)}}},[_c('CIcon',{attrs:{"name":"cil-media-play"}})],1)],_c('CButton',{staticClass:"mr-3",attrs:{"title":_vm.$t('ui.edit'),"size":"sm","color":"info"},on:{"click":function($event){return _vm.edit(item)}}},[_c('CIcon',{attrs:{"name":"cil-pencil"}})],1),_c('CButton',{staticClass:"mr-3",attrs:{"title":_vm.$t('ui.copy'),"size":"sm","color":"info"},on:{"click":function($event){return _vm.clone(item)}}},[_c('CIcon',{attrs:{"name":"cil-clone"}})],1),_c('CButton',{staticClass:"mr-3",attrs:{"title":_vm.$t('ui.copy_with_creatives'),"size":"sm","color":"info"},on:{"click":function($event){return _vm.cloneWithCreatives(item)}}},[_c('CIcon',{attrs:{"name":"cil-copy"}})],1),_c('CButton',{attrs:{"title":_vm.$t('ui.delete'),"size":"sm","color":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.remove(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],2)])]}}])}),(_vm.isShowedPagination)?_c('CPagination',{attrs:{"pages":_vm.totalPages,"active-page":_vm.activePage,"size":"sm"},on:{"update:activePage":_vm.onChangePage}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }