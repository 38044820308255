<template>
  <div>
    <MbnTableTools
      :search="search"
      :limit="limit"
      @updateSearchQuery="search = $event"
      @updateLimit="updateLimit"
      @resetSearch="resetSearch"
    />

    <CRow>
      <CCol>
        <CCard>
          <CCardHeader class="d-flex align-items-center justify-content-between">
            <span>{{ $t('sidebar.campaigns') }}</span>
            <CButton size="sm" color="info" @click="createNewRecord"
              ><CIcon name="cil-plus" />&nbsp; {{ $t('ui.add_new_campaign') }}</CButton
            >
          </CCardHeader>
          <CCardBody>
            <MbnErrorMessage v-if="error" />

            <CDataTable
              v-else
              :fields="fields"
              :items="tableItems"
              striped
              add-table-classes="section-table"
              :no-items-view="{ noItems: $t('ui.no_items_available') }"
              sorter
              clickable-rows
              :loading="isLoading"
              hover
              @row-clicked="edit"
            >
              <template #browser="{ item }">
                <td>
                  <CListGroup v-if="item.browser">
                    <CListGroupItem v-for="(browser, index) in item.browser" :key="index" class="table-list-group">
                      {{ browser.name }}
                    </CListGroupItem>
                  </CListGroup>

                  <span v-else>
                    {{ $t('ui.all') }}
                  </span>
                </td>
              </template>
              <template #country="{ item }">
                <td>
                  <CListGroup v-if="item.country">
                    <CListGroupItem v-for="(country, index) in item.country" :key="index" class="table-list-group">
                      {{ country.name }}
                    </CListGroupItem>
                  </CListGroup>

                  <span v-else>
                    {{ $t('ui.all') }}
                  </span>
                </td>
              </template>
              <template #language="{ item }">
                <td>
                  <CListGroup v-if="item.language">
                    <CListGroupItem v-for="(language, index) in item.language" :key="index" class="table-list-group">
                      {{ language.name }}
                    </CListGroupItem>
                  </CListGroup>

                  <span v-else>
                    {{ $t('ui.all') }}
                  </span>
                </td>
              </template>
              <template #platform="{ item }">
                <td>
                  <CListGroup v-if="item.platform">
                    <CListGroupItem v-for="(platform, index) in item.platform" :key="index" class="table-list-group">
                      {{ platform.name }}
                    </CListGroupItem>
                  </CListGroup>

                  <span v-else>
                    {{ $t('ui.all') }}
                  </span>
                </td>
              </template>
              <template #siteWhiteList="{ item }">
                <td>
                  <CListGroup>
                    <CListGroupItem v-for="(site, index) in item.siteWhiteList" :key="index" class="table-list-group">
                      {{ site.name }}
                    </CListGroupItem>
                  </CListGroup>
                </td>
              </template>
              <template #active="{ item }">
                <td>
                  <CBadge :color="getBadge(item.active)">
                    {{ item.active ? $t('ui.active') : $t('ui.inactive') }}
                  </CBadge>
                </td>
              </template>
              <template #show_details="{ item }">
                <td>
                  <div class="d-flex">
                    <template>
                      <CButton
                        v-if="item.active"
                        :title="$t('ui.stop')"
                        size="sm"
                        class="mr-3"
                        color="info"
                        @click="deactivate(item)"
                      >
                        <CIcon name="cil-media-pause" />
                      </CButton>
                      <CButton
                        v-else
                        :title="$t('ui.start')"
                        size="sm"
                        class="mr-3"
                        color="info"
                        @click="activate(item)"
                      >
                        <CIcon name="cil-media-play" />
                      </CButton>
                    </template>
                    <CButton :title="$t('ui.edit')" size="sm" class="mr-3" color="info" @click="edit(item)">
                      <CIcon name="cil-pencil" />
                    </CButton>
                    <CButton :title="$t('ui.copy')" size="sm" class="mr-3" color="info" @click="clone(item)">
                      <CIcon name="cil-clone" />
                    </CButton>
                    <CButton
                      :title="$t('ui.copy_with_creatives')"
                      size="sm"
                      class="mr-3"
                      color="info"
                      @click="cloneWithCreatives(item)"
                    >
                      <CIcon name="cil-copy" />
                    </CButton>
                    <CButton :title="$t('ui.delete')" size="sm" color="danger" @click.prevent="remove(item)">
                      <CIcon name="cil-trash" />
                    </CButton>
                  </div>
                </td>
              </template>
            </CDataTable>

            <CPagination
              v-if="isShowedPagination"
              :pages="totalPages"
              :active-page="activePage"
              size="sm"
              @update:activePage="onChangePage"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { GET_CAMPAIGN_LIST, EDIT_CAMPAIGN, CLONE_CAMPAIGN, GET_DOMAIN_LIST } from '@/store/action-types/campaigns';
import {
  GET_PLATFORM_LIST,
  GET_BROWSER_LIST,
  GET_COUNTRY_LIST,
  GET_LANGUAGE_LIST,
} from '@/store/action-types/dictionary';
import { limits } from '@/helpers/const';
import { getBadge } from '@/helpers/utils';
import searchByTable from '@/helpers/search-by-table';
import MbnTableTools from '@/components/TableTools';
import MbnErrorMessage from '@/components/ErrorMessage';

export default {
  name: 'Campaigns',
  components: {
    MbnTableTools,
    MbnErrorMessage,
  },
  data() {
    return {
      limit: this.$route.query.limit || limits[0].value,
      search: '',
      filtered: '',
      activePage: Number(this.$route.query.page || '1'),
    };
  },
  computed: {
    ...mapState('campaigns', ['error', 'isLoading', 'total']),
    ...mapGetters('campaigns', ['campaignList']),
    tableItems() {
      return this.filtered ? this.filtered : this.campaignList;
    },
    totalPages() {
      return Math.ceil(this.total / this.limit);
    },
    offset() {
      return this.activePage * this.limit - this.limit;
    },
    isShowedPagination() {
      return this.totalPages > 1 && !this.filtered;
    },
    fields() {
      return [
        { key: 'id', label: this.$t('campaigns.id') },
        { key: 'userId', label: this.$t('campaigns.user_id') },
        { key: 'name', label: this.$t('campaigns.name') },
        { key: 'capping', label: this.$t('campaigns.capping') },
        { key: 'browser', label: this.$t('campaigns.browser') },
        { key: 'country', label: this.$t('campaigns.country') },
        { key: 'language', label: this.$t('campaigns.language') },
        { key: 'platform', label: this.$t('campaigns.platform') },
        { key: 'siteWhiteList', label: this.$t('campaigns.site_white_list') },
        { key: 'active', label: this.$t('campaigns.active') },
        { key: 'show_details', label: this.$t('ui.actions'), sorter: false },
      ];
    },
  },
  watch: {
    search(value) {
      this.filterData(value);
    },
    activePage() {
      this.fetchCampaignList();
    },
    limit() {
      this.fetchCampaignList();
    },
  },
  mounted() {
    Promise.all([
      this.GET_DOMAIN_LIST(),
      this.GET_PLATFORM_LIST(),
      this.GET_BROWSER_LIST(),
      this.GET_COUNTRY_LIST(),
      this.GET_LANGUAGE_LIST(),
      this.GET_CAMPAIGN_LIST({
        limit: this.limit,
        offset: this.offset,
      }),
    ]);
  },
  methods: {
    ...mapActions('campaigns', [GET_CAMPAIGN_LIST, EDIT_CAMPAIGN, CLONE_CAMPAIGN, GET_DOMAIN_LIST]),
    ...mapActions('dictionary', [GET_PLATFORM_LIST, GET_BROWSER_LIST, GET_COUNTRY_LIST, GET_LANGUAGE_LIST]),
    resetSearch() {
      this.search = '';
    },
    updateLimit(value) {
      this.limit = value;
      this.activePage = 1;
      this.$router.push({ query: { limit: this.limit } });
    },
    createNewRecord() {
      this.$router.push({
        name: 'campaign-create',
      });
    },
    filterData(query) {
      this.filtered = searchByTable(query, this.campaignList);
    },
    getBadge(status) {
      return getBadge(status);
    },
    edit(item, index, column) {
      //do not remove index param!
      if (column === 'show_details') {
        return;
      }

      this.$router.push({
        name: 'campaign-edit',
        params: { id: item.id },
      });
    },
    onChangePage(page) {
      this.activePage = page;
      this.$router.push({ query: { limit: this.limit, page } });
    },
    fetchCampaignList() {
      this.GET_CAMPAIGN_LIST({
        limit: this.limit,
        offset: this.offset,
      });
    },
    remove(record) {
      this.update(record, { field: 'deleted', value: true });
    },
    clone(campaign) {
      this.cloneCampaign(campaign, false);
    },
    cloneWithCreatives(campaign) {
      this.cloneCampaign(campaign, true);
    },
    activate(record) {
      this.update(record, { field: 'active', value: true });
    },
    deactivate(record) {
      this.update(record, { field: 'active', value: false });
    },
    update(record, prop) {
      const campaign = { ...record };
      campaign[prop.field] = prop.value;
      campaign.country = this.getIdsToString(campaign.country);
      campaign.browser = this.getIdsToString(campaign.browser);
      campaign.platform = this.getIdsToString(campaign.platform);
      campaign.language = this.getIdsToString(campaign.language);
      campaign.siteWhiteList = this.getIdsToString(campaign.siteWhiteList);

      this.EDIT_CAMPAIGN({ id: campaign.id, formData: campaign })
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.updated'),
          });
          this.fetchCampaignList();
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.updating_record_error'),
          });
        });
    },
    getIdsToString(list) {
      return Array.isArray(list) ? list.map((item) => item.id).join(',') : list;
    },
    cloneCampaign(campaign, withCreative) {
      this.CLONE_CAMPAIGN({ id: campaign.id, withCreative })
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.cloned'),
          });
          this.fetchCampaignList();
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.updating_record_error'),
          });
        });
    },
  },
};
</script>
